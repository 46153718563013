import { connect } from 'react-redux'
import HousesLookup from '../pages/HousesLookup.jsx'
import getAllHouses from '../actions/getAllHouses'

const mapStateToProps = (state) => {
  const { token, sub } = state.app
  const { houses } = state.houses
  return {
    token,
    sub,
    houses,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAllHouses: ({ token, sub }) => dispatch(getAllHouses({ token, sub })),
})

const retVal = connect(mapStateToProps, mapDispatchToProps)(HousesLookup)

export default retVal
