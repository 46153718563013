import React from 'react'
import PropTypes from 'prop-types'
import images from '../images'

const formatUsername = (username) =>
  username.length <= 7 ? username : `${username.slice(0, 7)}...`
const SmallUser = ({
  callerSub,
  sub: userSub,
  profilePhoto,
  username,
  shorten = false,
}) => (
  <div className={shorten ? 'ShortenedSmallUser' : 'SmallUser'}>
    <img
      className="SmallUserLogo"
      // eslint-disable-next-line no-undef
      src={profilePhoto || images.ProfileImage}
      alt="profile logo"
    />
    <div className="SmallUserName">
      <p className="SmallUserName">
        {callerSub === userSub
          ? 'You'
          : shorten
          ? formatUsername(username)
          : username}
      </p>
    </div>
  </div>
)

SmallUser.propTypes = {
  callerSub: PropTypes.string,
  username: PropTypes.string,
  sub: PropTypes.string,
  profilePhoto: PropTypes.string,
  shorten: PropTypes.bool,
}

export default SmallUser
