import { connect } from 'react-redux'
import UserRequests from '../components/UsersRequests.jsx'
import getUsersRequests from '../actions/getUsersRequests.js'
import respondToHouseRequest from '../actions/respondToHouseRequest.js'

const mapStateToProps = (state) => {
  const { token, sub } = state.app
  const { usersRequests } = state.user
  return {
    token,
    sub,
    usersRequests,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getUsersRequests: ({ token, sub }) =>
    dispatch(getUsersRequests({ token, sub })),
  respondToHouseRequest: ({ token, sub, houseId, approve }) =>
    dispatch(respondToHouseRequest({ token, sub, houseId, approve })),
})

const retVal = connect(mapStateToProps, mapDispatchToProps)(UserRequests)

export default retVal
