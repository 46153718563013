import React, { useEffect, useState } from 'react'
import LoadingWithHeader from './LoadingWithHeader.jsx'
import UserHeader from '../containers/UserHeader.js'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import images from '../images/index.js'
import Modal from '../components/Modal.jsx'

const HousesLookup = ({ token, getAllHouses, houses, sub }) => {
  const { houseId } = useParams()
  const [houseToSendRequest, setHouseToSendRequest] = useState({})
  const [confirm, setConfirm] = useState(false)
  const [sendingRequest, setSendingRequest] = useState(false)
  const [loading, setLoading] = useState(houses.length === 0)

  useEffect(() => {
    const getAllHousesHelper = async () => {
      const users = await getAllHouses({ token, sub })
      if (!users) window.location = '/house/' + houseId
      setLoading(false)
    }

    if (loading) getAllHousesHelper()
  }, [])

  if (loading) {
    return <LoadingWithHeader message="Looking for house" />
  }

  const confirmModal = () => (
    <Modal showModal={confirm} setShowModal={setConfirm}>
      {sendingRequest ? (
        <div>
          <h1>
            Confirm you would like to send a request to{' '}
            {houseToSendRequest.houseName}
          </h1>
          <button
            onClick={() => {
              setSendingRequest(true)

              setSendingRequest(false)
            }}
          >
            Send Request
          </button>
        </div>
      ) : (
        <img src={images.LoadingImage} />
      )}
    </Modal>
  )

  const HouseToAdd = ({ houseName, houseId }) => (
    <div className="HousesLookupHouseDiv" key={houseId}>
      <img
        onClick={() => {
          setConfirm(true)
          setHouseToSendRequest({ houseName, houseId })
        }}
        className="HousesLookupHouseDivLogo"
        src={images.AddHouseImage}
        alt="house logo"
      />
      <h3>{houseName}</h3>
    </div>
  )

  return (
    <div className="HousesLookupPage">
      {confirmModal()}
      <UserHeader />
      <div className="HousesLookupPageContent">
        <div className="HousesLookupPageHousesSection">
          {houses.map((house) => (
            <HouseToAdd key={house.houseId} {...house} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default HousesLookup

HousesLookup.propTypes = {
  houseId: PropTypes.string,
  houseName: PropTypes.string,
  houses: PropTypes.arrayOf(PropTypes.shape({ houseName: PropTypes.string })),
  sub: PropTypes.string,
  token: PropTypes.string,
  house: PropTypes.shape({
    houseName: PropTypes.string,
    houseId: PropTypes.string,
  }),
  getAllHouses: PropTypes.func,
  createHouseRequest: PropTypes.func,
}
