import { connect } from 'react-redux'
import App from '../components/App.jsx'

const mapStateToProps = (state) => {
  const { token, sub } = state.app

  return {
    token,
    sub,
  }
}

const mapDispatchToProps = (dispatch) => ({
  uploadMetadata: ({ token, sub }) =>
    dispatch({ type: 'METADATA_UPLOADED', token, sub }),
})

const retVal = connect(mapStateToProps, mapDispatchToProps)(App)

export default retVal
