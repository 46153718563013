const requests = (
  state = {
    requests: [],
  },
  action,
) => {
  switch (action.type) {
    case 'ALL_REQUESTS_UPLOADED': {
      return Object.assign({}, state, { requests: action.requests })
    }

    case 'REQUEST_CREATED': {
      const requests = [...state.requests]

      requests.push(action.request)

      return Object.assign({}, state, { requests })
    }

    default: {
      return state
    }
  }
}

export default requests
