import { connect } from 'react-redux'
import TaskTypes from '../components/TaskTypes.jsx'
import createTaskType from '../actions/createTaskType.js'
import createTask from '../actions/createTask.js'

const mapStateToProps = (state) => {
  const { token } = state.app
  const { taskTypes } = state.house
  return {
    token,
    taskTypes,
  }
}

const mapDispatchToProps = (dispatch) => ({
  createTaskType: ({ token, houseId, taskTypeData }) =>
    dispatch(createTaskType({ token, houseId, taskTypeData })),
  createTask: ({ token, houseId, taskData }) =>
    dispatch(createTask({ token, houseId, taskData })),
})

const retVal = connect(mapStateToProps, mapDispatchToProps)(TaskTypes)

export default retVal
