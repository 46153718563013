import axios from 'axios'

const throwError = ({ response, message }) => {
  if (typeof response.data === 'string') throw Error(response.data)

  if (typeof response.data.message === 'string')
    throw Error(response.data.message)
  throw new Error(message)
}
class HousematesClient {
  constructor({ axiosClient }) {
    const axiosConfig = {
      baseURL:
        // eslint-disable-next-line no-undef
        process.env.NODE_ENV === 'production'
          ? 'https://api-housemates.rohitsaigal.com'
          : `http://127.0.0.1:3010`,
      validateStatus: () => true, // Don't throw HTTP exceptions. Delegate to the tests to decide which error is acceptable
    }
    this.axiosClient = axiosClient || axios.create(axiosConfig)
    this.setAuthHeader('usersub123')
  }

  setAuthHeader(token) {
    this.axiosClient.defaults.headers.common.Authorization = `Bearer ${token}`
    return token
  }

  // Livechecks

  async getLivecheck() {
    return this.axiosClient.get(`/livecheck/public`)
  }

  async getPrivateLivecheck() {
    return this.axiosClient.get(`/livecheck/private`)
  }

  // Users

  async createUser({ username, token, sub, profilePicture }) {
    const formData = new FormData()
    formData.append('username', username)
    formData.append('sub', sub)
    formData.append('profilePicture', profilePicture)
    this.setAuthHeader(token)
    const response = await this.axiosClient.post('/users', formData)
    if (response.status !== 201) throw Error(response.data)
    return response.data
  }

  async getUser({ sub, token }) {
    this.setAuthHeader(token)
    const response = await this.axiosClient.get(`/users/${sub}`)

    if (response.status === 200) return response.data

    if (typeof response.data.message === 'string')
      throw Error(response.data.message)
    throw new Error('No User Found')
  }

  async getImage({ url, token }) {
    const headers = new Headers()
    headers.set('Authorization', `Bearer ${token}`)
    const response = await fetch(url, { headers })
    const blob = await response.blob()
    return URL.createObjectURL(blob)
  }

  async getAllUsers({ token }) {
    this.setAuthHeader(token)
    const response = await this.axiosClient.get(`/users`)

    if (response.status === 200) return response.data

    if (typeof response.data.message === 'string')
      throw Error(response.data.message)
    throw new Error('No User Found')
  }

  async getUsersRequests({ sub, token }) {
    this.setAuthHeader(token)
    const response = await this.axiosClient.get(`/users/${sub}/requests`)
    if (response.status === 200) return response.data

    throwError({
      response,
      message: 'There was an error getting the Users Request ',
    })
  }

  async getUsersAssignedTaskItems({ sub, token }) {
    this.setAuthHeader(token)
    const response = await this.axiosClient.get(`/users/${sub}/taskItems`)
    if (response.status === 200) return response.data

    throwError({
      response,
      message: 'There was an error getting the Users Tasks ',
    })
  }
  async getHousesUserBelongsTo({ sub, token }) {
    this.setAuthHeader(token)

    const response = await this.axiosClient.get(`/users/${sub}/houses`)

    if (response.status === 200) return response.data
    if (typeof response.data.message === 'string')
      throw Error(response.data.message)
    throw new Error('There was an error getting the users houses')
  }
  async deleteUser({ sub, token }) {
    this.setAuthHeader(token)
    return this.axiosClient.delete(`/users/${sub}`)
  }

  // Houses
  async createHouse({ houseName, weekStart = 0, sub, token }) {
    const house = {
      houseName,
      weekStart,
      admin: sub,
    }

    this.setAuthHeader(token)
    const response = await this.axiosClient.post('/houses', house)

    if (response.status === 200) return { houseName, weekStart, sub }
    if (typeof response.data === 'string') throw Error(response.data)

    if (typeof response.data.message === 'string')
      throw Error(response.data.message)
    throw new Error('There was an error creating the users houses')
  }

  async getHouse({ houseId, token }) {
    this.setAuthHeader(token)
    const response = await this.axiosClient.get(`/houses/${houseId}`)
    if (response.status === 200) return response.data
    if (typeof response.data.message === 'string')
      throw Error(response.data.message)
    throw new Error('No House Found')
  }

  async getAllHouses({ token }) {
    this.setAuthHeader(token)
    const response = await this.axiosClient.get(`/houses`)

    if (response.status === 200) return response.data

    throwError({
      response,
      message: 'There was an error getting all the houses',
    })
  }

  async getHouseUsers({ houseId, token }) {
    this.setAuthHeader(token)
    const response = await this.axiosClient.get(`/houses/${houseId}/users`)

    if (response.status === 200) return response.data

    throwError({
      response,
      message: 'There was an error getting the users for this house',
    })
  }

  async getHouseTaskTypes({ houseId, token }) {
    this.setAuthHeader(token)
    const response = await this.axiosClient.get(`/houses/${houseId}/taskTypes`)

    if (response.status === 200) return response.data

    throwError({
      response,
      message: 'There was an error getting the task types for this house',
    })
  }

  async getHouseTasks({ houseId, token }) {
    this.setAuthHeader(token)
    const response = await this.axiosClient.get(`/houses/${houseId}/tasks`)

    if (response.status === 200) return response.data

    throwError({
      response,
      message: 'There was an error getting the task for this house',
    })
  }

  // House Requests

  async createHouseRequest({ type, sub, username, houseId, token }) {
    const request = {
      type,
      sub,
      username,
    }
    this.setAuthHeader(token)
    const response = await this.axiosClient.post(
      `/houses/${houseId}/requests`,
      request,
    )
    if (response.status === 200) return response.data
    return response
  }

  async getHouseRequests({ houseId, token }) {
    this.setAuthHeader(token)
    const response = await this.axiosClient.get(`/houses/${houseId}/requests`)

    if (response.status === 200) return response.data
    throwError({
      response,
      message: 'There was an issue getting the House Requests',
    })
  }

  async respondToHouseRequest({ houseId, sub, token, approve }) {
    const houseRequestResponse = { houseId, sub, approve }
    this.setAuthHeader(token)
    const response = await this.axiosClient.post(
      `/houses/${houseId}/requests/${sub}:respond`,
      houseRequestResponse,
    )

    if (response.status === 201) return true

    throwError({
      response,
      message: 'There was an error responding to the house request',
    })
  }

  // Task Types

  async createTaskType({ houseId, taskTypeData, token }) {
    this.setAuthHeader(token)
    const response = await this.axiosClient.post(
      `/houses/${houseId}/taskTypes`,
      taskTypeData,
    )
    if (response.status === 200) return response.data

    return throwError({
      response,
      message: 'There was an error creating a task type',
    })
  }

  async editTaskType({ houseId, taskTypeData, taskTypeId, token }) {
    this.setAuthHeader(token)
    const response = await this.axiosClient.put(
      `/houses/${houseId}/taskTypes/${taskTypeId}`,
      taskTypeData,
    )
    return response
  }

  async approveTaskType({ houseId, taskTypeId, token }) {
    this.setAuthHeader(token)

    const response = await this.axiosClient.post(
      `/houses/${houseId}/taskTypes/${taskTypeId}:approve`,
    )
    return response
  }

  async getTaskType({ houseId, taskTypeId, token }) {
    this.setAuthHeader(token)

    return this.axiosClient.get(`/houses/${houseId}/taskTypes/${taskTypeId}`)
  }

  async deleteTaskType({ houseId, taskTypeId, token }) {
    this.setAuthHeader(token)

    return this.axiosClient.delete(`/houses/${houseId}/taskTypes/${taskTypeId}`)
  }

  // Tasks

  async createTask({ houseId, taskData, token }) {
    this.setAuthHeader(token)

    const response = await this.axiosClient.post(
      `/houses/${houseId}/tasks`,
      taskData,
    )
    if (response.status === 200) return response.data

    return throwError({
      response,
      message: 'There was an error creating a task type',
    })
  }

  async getTask({ houseId, taskId, token }) {
    this.setAuthHeader(token)
    return this.axiosClient.get(`/houses/${houseId}/tasks/${taskId}`)
  }

  // Task Items
  async getTaskItem({ houseId, taskItemId, token }) {
    this.setAuthHeader(token)
    return this.axiosClient.get(`/houses/${houseId}/taskItems/${taskItemId}`)
  }

  async deleteTask({ houseId, taskId, token }) {
    this.setAuthHeader(token)
    return this.axiosClient.delete(`/houses/${houseId}/tasks/${taskId}`)
  }

  async assignTaskItem({ houseId, taskItemId, sub, token }) {
    this.setAuthHeader(token)
    const response = await this.axiosClient.post(
      `/houses/${houseId}/taskItems/${taskItemId}:assign`,
      {
        sub,
      },
    )
    if (response.status === 204) return true
    else
      return throwError({
        response,
        message: 'There was an error assigning the user to the task item',
      })
  }

  async completeTaskItem({ houseId, taskItemId, token }) {
    this.setAuthHeader(token)
    const response = await this.axiosClient.post(
      `/houses/${houseId}/taskItems/${taskItemId}:complete`,
    )

    if (response.status >= 200 && response.status < 300) return true
    else
      return throwError({
        response,
        message: 'There was an error completing the task',
      })
  }
}

const housematesClient = new HousematesClient({})

export default housematesClient
