const user = (
  state = {
    user: {
      username: '',
      sub: '',
      profilePicture: '',
    },
    houses: [],
    usersRequests: [],
    incompleteTaskItems: [],
    completeTaskItems: [],
    houseNames: {},
  },
  action,
) => {
  switch (action.type) {
    case 'USER_PICKED_UP_TASK_ITEM': {
      const incompleteTaskItems = [...state.incompleteTaskItems]
      incompleteTaskItems.push(action.taskItem)
      return Object.assign({}, state, { incompleteTaskItems })
    }
    case 'TASK_ITEM_COMPLETED': {
      const completeTaskItems = [...state.completeTaskItems]

      const completedTaskItemIdx = state.incompleteTaskItems.findIndex(
        ({ taskItemId }) => taskItemId === action.taskItemId,
      )
      completeTaskItems.push({
        ...state.incompleteTaskItems[completedTaskItemIdx],
        complete: true,
      })

      const incompleteTaskItems = [
        ...state.incompleteTaskItems.filter(
          ({ taskItemId }) => taskItemId !== action.taskItemId,
        ),
      ]

      return Object.assign({}, state, {
        incompleteTaskItems,
        completeTaskItems,
      })
    }

    case 'USER_UPLOADED': {
      return Object.assign({}, state, {
        user: action.user,
        incompleteTaskItems: action.taskItems
          ? action.taskItems.filter(({ complete }) => !complete)
          : [],
        completeTaskItems: action.taskItems
          ? action.taskItems.filter(({ complete }) => complete)
          : [],
      })
    }
    case 'USER_UPLOAD_FAILED': {
      return Object.assign({}, state, { errorMessage: action.errorMessage })
    }

    case 'USERS_REQUESTS_UPLOADED': {
      return Object.assign({}, state, { usersRequests: action.usersRequests })
    }

    case 'USER_DELETED': {
      return Object.assign({}, state, {
        user: {
          username: '',
          sub: '',
        },
      })
    }

    case 'HOUSES_USER_BELONGS_TO_UPLOADED': {
      return Object.assign({}, state, {
        houses: action.houses,
        houseNames: action.houses.reduce(
          (houseNames, house) => ({
            ...houseNames,
            [house.houseId]: house.houseName,
          }),
          {},
        ),
      })
    }

    case 'HOUSE_CREATED': {
      const houses = [...state.houses]
      houses.push(action.house)
      return Object.assign({}, state, {
        houses,
      })
    }

    default: {
      return state
    }
  }
}

export default user
