import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import PropTypes from 'prop-types'
import images from '../images'
const LogoutButton = ({ className }) => {
  const { logout } = useAuth0()

  return (
    <img
      className={className}
      onClick={() => logout({ returnTo: window.location.origin })}
      src={images.LogoutImage}
      alt="logout logo"
    />
  )
}

export default LogoutButton

LogoutButton.propTypes = {
  className: PropTypes.string,
}
