import React from 'react'
import PropTypes from 'prop-types'

const Alert = ({ message, type, alertId, deleteAlert }) => (
  <div className="AlertDiv">
    <span onClick={() => deleteAlert({ alertId })}>&times;</span>
    <p className={'Alert' + type[0].toUpperCase() + type.slice(1)}>
      {type + ': ' + message}
    </p>
  </div>
)
const Alerts = ({ alerts, deleteAlert }) => {
  return (
    <div className="AlertsDiv">
      {alerts.map((alert) => (
        <Alert key={alert.alertId} {...alert} deleteAlert={deleteAlert} />
      ))}
    </div>
  )
}

Alerts.propTypes = {
  deleteAlert: PropTypes.func,
  alerts: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string,
      type: PropTypes.string,
      alertId: PropTypes.string,
    }),
  ),
}

Alert.propTypes = {
  deleteAlert: PropTypes.func,
  message: PropTypes.string,
  alertId: PropTypes.string,
  type: PropTypes.string,
}
export default Alerts
