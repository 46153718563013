import React, { useEffect, useState } from 'react'
import LoadingWithHeader from './LoadingWithHeader.jsx'
import UserHeader from '../containers/UserHeader.js'
import Leaderboard from '../containers/Leaderboard.js'
import PropTypes from 'prop-types'
import { useParams, useNavigate } from 'react-router-dom'
import TaskTypes from '../containers/TaskTypes'
import Tasks from '../containers/Tasks'

const AddHouse = ({ token, getHouse, house }) => {
  const { houseId } = useParams()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(
    !house.houseName || house.houseId !== houseId,
  )

  useEffect(() => {
    const loadHouse = async () => {
      const houseExists = await getHouse({ token, houseId })
      if (!houseExists) navigate('/')
      setLoading(false)
    }

    if (loading) {
      loadHouse()
    }
  }, [getHouse, house])

  if (loading) {
    return <LoadingWithHeader message="Looking for house" />
  }

  const { houseName } = house
  return (
    <div className="HousePage">
      <UserHeader />
      <div className="HousePageContent">
        <div>
          <h1 className="HousePageHeader">{houseName}</h1>
        </div>
        <Leaderboard />
        <TaskTypes />
        <Tasks />
      </div>
    </div>
  )
}

export default AddHouse

AddHouse.propTypes = {
  sub: PropTypes.string,
  token: PropTypes.string,
  house: PropTypes.shape({
    houseName: PropTypes.string,
    houseId: PropTypes.string,
  }),
  getHouse: PropTypes.func,
  createHouse: PropTypes.func,
}
