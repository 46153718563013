import React, { useState, useEffect } from 'react'
import LogOutButton from '../components/LogOutButton.jsx'
import { Link } from 'react-router-dom'
import images from '../images/index.js'
import PropTypes from 'prop-types'
import Loading from './Loading.jsx'

const UserHeader = ({ user, token, sub, getUser }) => {
  const [loading, setLoading] = useState(user.username === '')

  useEffect(() => {
    const checkForUser = async () => {
      await getUser({ token, sub })
      setLoading(false)
    }

    if (loading) {
      setLoading(true)
      checkForUser()
    }
    if (user) {
      setLoading(false)
    }
  }, [getUser, token, sub, user, user.username])

  if (loading) {
    return <Loading />
  }
  console.log({ token, sub, setLoading, loading })
  const HeaderButtons = () => (
    <div className="UserHeaderButtons">
      <LogOutButton className="UserHeaderButton" />
    </div>
  )
  return (
    <div className="UserHeaderDiv">
      <div className="UserHeader">
        {user && (
          <img
            className="UserHeaderLogo"
            src={user.profilePicture}
            alt="housemates logo"
          />
        )}
        <Link to="/">
          <img
            className="UserHeaderLogo"
            src={images.HousematesImage}
            alt="housemates logo"
          />
        </Link>

        <div className="UserHeaderLogout">
          <HeaderButtons />
        </div>
      </div>
      <hr />
    </div>
  )
}

UserHeader.propTypes = {
  sub: PropTypes.string,
  token: PropTypes.string,
  user: PropTypes.shape({
    username: PropTypes.string,
    profilePicture: PropTypes.string,
  }),
  getUser: PropTypes.func,
}
export default UserHeader
