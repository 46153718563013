import { connect } from 'react-redux'
import AddUserToHouse from '../pages/AddUserToHouse.jsx'
import getAllUsers from '../actions/getAllUsers'
import getHouse from '../actions/getHouse'
import getHouseRequests from '../actions/getHouseRequests'
import createHouseRequest from '../actions/createHouseRequest.js'
const mapStateToProps = (state) => {
  const { token, sub } = state.app
  const { users } = state.users
  const { house } = state.house
  const { requests } = state.requests

  const userIdsToOmit = requests.map(({ sub }) => sub)
  state.house.users.forEach((user) => userIdsToOmit.push(user.sub))
  return {
    requests,
    house,
    token,
    sub,
    users: users.filter(
      (user) => user.sub !== sub && !userIdsToOmit.includes(user.sub),
    ),
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAllUsers: ({ token }) => dispatch(getAllUsers({ token })),
  getHouse: ({ token, houseId }) => dispatch(getHouse({ token, houseId })),
  getHouseRequests: ({ token, houseId }) =>
    dispatch(getHouseRequests({ token, houseId })),
  createHouseRequest: ({ token, sub, type, username, houseId }) =>
    dispatch(createHouseRequest({ token, sub, type, username, houseId })),
})

const retVal = connect(mapStateToProps, mapDispatchToProps)(AddUserToHouse)

export default retVal
