import React from 'react'
import PropTypes from 'prop-types'
import images from '../images'
import SmallUser from './SmallUser.jsx'
import { useParams, useNavigate } from 'react-router'
const Leaderboard = ({ users, sub }) => {
  const { houseId } = useParams()
  const navigate = useNavigate()
  return (
    <div className="LeaderboardDiv">
      <div className="Leaderboard">
        <div className="LeaderboardUsers">
          {users.map((user) => (
            <SmallUser key={user.name} {...user} callerSub={sub} shorten />
          ))}
        </div>

        <div className="LeaderboardAddUserDiv">
          <img
            className="LeaderboardAddUserLogo"
            src={images.AddUserImage}
            alt="add user logo"
            onClick={() => {
              navigate('/houses/' + houseId + '/addUser')
            }}
          />
        </div>
      </div>
      <hr />
    </div>
  )
}

Leaderboard.propTypes = {
  sub: PropTypes.string,
  profilePhoto: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.shape({ sub: PropTypes.string })),
}

export default Leaderboard
