import api from '../api'
import createAlert from '../createAlert'

export default function respondToHouseRequest({
  sub,
  houseId,
  token,
  approve,
}) {
  return async (dispatch) => {
    try {
      await api.respondToHouseRequest({
        sub,
        approve,
        houseId,
        token,
      })
      if (approve) dispatch({ type: 'REQUEST_APPROVED', sub })
      return true
    } catch (error) {
      dispatch({
        type: 'ADD_ALERT',
        alert: createAlert({ message: error.message, type: 'error' }),
      })
      return false
    }
  }
}
