import React from 'react'
import PropTypes from 'prop-types'
import TaskItemCard from './TaskItemCard/TaskItemCard.jsx'
const Tasks = ({
  unassignedTaskItems,
  sub,
  token,
  taskTypesMap,
  pickUpTaskItem,
  houseName,
}) => {
  const TaskTypesHeader = () => <h2 className="TasksHeaderTitle">Tasks</h2>

  const NoTaskDisplay = () => (
    <h3 className="NoTasksDisplay">Schedule a task to get started!</h3>
  )

  const TaskItemCards = () => (
    <div className="TaskItemCards">
      {unassignedTaskItems
        .filter(({ assignedTo }) => !assignedTo)
        .map((taskItem) => (
          <TaskItemCard
            houseName={houseName}
            sub={sub}
            token={token}
            pickUpTaskItem={pickUpTaskItem}
            key={taskItem.taskId}
            {...taskItem}
            taskType={taskTypesMap[taskItem.taskTypeId]}
          />
        ))}
    </div>
  )
  return (
    <div className="TaskSection">
      <TaskTypesHeader />
      {unassignedTaskItems.length ? <TaskItemCards /> : <NoTaskDisplay />}
    </div>
  )
}

Tasks.propTypes = {
  sub: PropTypes.string,
  houseName: PropTypes.string,
  token: PropTypes.string,
  pickUpTaskItem: PropTypes.func,
  taskTypesMap: PropTypes.shape({
    taskType: PropTypes.shape({
      taskTypeId: PropTypes.string,
      points: PropTypes.string,
      value: PropTypes.string,
    }),
  }),
  unassignedTaskItems: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string }),
  ),
}
export default Tasks
