import api from '../api'
import createAlert from '../createAlert'
export default function getUser({ token, sub }) {
  return async (dispatch) => {
    try {
      const { user } = await api.getUser({ token, sub })
      const { taskItems } = await api.getUsersAssignedTaskItems({ sub, token })
      user.profilePicture = await api.getImage({
        url: user.profilePicture,
        token,
      })
      dispatch({ type: 'USER_UPLOADED', user, taskItems })
      return true
    } catch (error) {
      if (error.message !== 'No User Found') {
        return dispatch({
          type: 'ADD_ALERT',
          alert: createAlert({ message: error.message, type: 'error' }),
        })
      }
      return false
    }
  }
}
