import api from '../api'
import createAlert from '../createAlert'

export default function createTask({ token, houseId, taskData }) {
  return async (dispatch) => {
    try {
      const task = await api.createTask({
        token,
        houseId,
        taskData,
      })
      dispatch({
        type: 'TASK_CREATED',
        task: { ...taskData, houseId, ...task },
      })
      return true
    } catch (error) {
      dispatch({
        type: 'ADD_ALERT',
        alert: createAlert({ message: error.message, type: 'error' }),
      })
      return false
    }
  }
}
