import api from '../api'
import createAlert from '../createAlert'
export default function getHousesUserBelongsTo({ token, sub }) {
  return async (dispatch) => {
    try {
      const { houses } = await api.getHousesUserBelongsTo({ token, sub })
      dispatch({ type: 'HOUSES_USER_BELONGS_TO_UPLOADED', houses })
      return true
    } catch (error) {
      dispatch({
        type: 'ADD_ALERT',
        alert: createAlert({ message: error.message, type: 'error' }),
      })
      return false
    }
  }
}
