import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from '../Modal.jsx'
import images from '../../images/index.js'

const CompleteTaskItemModal = ({
  taskTypeProperties,
  taskItem,
  sub,
  showModal,
  setShowModal,
  houseId,
  completeTaskItem,
  token,
}) => {
  const [loading, setLoading] = useState(false)

  const modalContent = (
    <>
      <h1>Are you sure you want to complete this task? </h1>
      {taskTypeProperties}
      <button
        onClick={async () => {
          setLoading(true)
          await completeTaskItem({ token, ...taskItem, sub, houseId })
          setLoading(false)
          setShowModal(false)
        }}
      >
        Complete
      </button>
      <button
        onClick={() => {
          setShowModal(false)
        }}
      >
        Cancel
      </button>
    </>
  )
  return (
    <Modal showModal={showModal} setShowModal={setShowModal}>
      {loading ? (
        <img src={images.LoadingImage} className="Loadinglogo" alt="logo" />
      ) : (
        modalContent
      )}
    </Modal>
  )
}

CompleteTaskItemModal.propTypes = {
  taskTypeProperties: PropTypes.node,
  taskItem: PropTypes.shape({ taskItemId: PropTypes.string }),
  token: PropTypes.string,
  houseId: PropTypes.string,
  sub: PropTypes.string,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  completeTaskItem: PropTypes.func,
}
export default CompleteTaskItemModal
