import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TaskTypeProperties from '../TaskTypeCard/TaskTypeProperties.jsx'
import PickUpTaskModal from '../Modals/PickUpTaskModal.jsx'
import CompleteTaskItemModal from '../Modals/CompleteTaskItemModal.jsx'
import images from '../../images/index.js'
const TaskItemCard = ({
  sub,
  token,
  complete,
  taskType,
  date,
  pickUpTaskItem,
  completeTaskItem,
  taskItemId,
  taskTypeId,
  houseName,
  houseId,
}) => {
  const [showPickUpTaskModal, setShowPickUpTaskModal] = useState(false)
  const [showCompleteTaskItemModal, setShowCompleteTaskItemModal] = useState(
    false,
  )

  const dateObject = new Date(date)
  const taskTypeProperties = (
    <TaskTypeProperties houseName={houseName} {...taskType} />
  )

  const pickUpButton = (
    <button
      onClick={() => {
        setShowPickUpTaskModal(true)
      }}
    >
      Pick Up
    </button>
  )

  const completeButton = (
    <button
      onClick={() => {
        setShowCompleteTaskItemModal(true)
      }}
    >
      Complete
    </button>
  )

  const TaskItemCardActions = () => (
    <div className="TaskItemCardActions">
      {pickUpTaskItem ? pickUpButton : completeButton}
    </div>
  )

  return (
    <div className="TaskItemCard">
      <PickUpTaskModal
        sub={sub}
        token={token}
        pickUpTaskItem={pickUpTaskItem}
        showModal={showPickUpTaskModal}
        taskType={taskType}
        setShowModal={setShowPickUpTaskModal}
        taskTypeProperties={taskTypeProperties}
        taskItem={{ taskItemId, houseId, taskTypeId, date, taskType }}
      />
      <CompleteTaskItemModal
        sub={sub}
        token={token}
        houseId={houseId}
        completeTaskItem={completeTaskItem}
        showModal={showCompleteTaskItemModal}
        setShowModal={setShowCompleteTaskItemModal}
        taskTypeProperties={taskTypeProperties}
        taskItem={{ taskItemId, houseId }}
      />
      {taskTypeProperties}
      <div className="TaskItemCardDateAndTime">
        <h3 className="TaskItemCardDateAndTimeHeader">
          {dateObject.toLocaleTimeString(undefined, {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          })}
        </h3>
      </div>
      {complete ? (
        <img
          className="TaskItemCardCompleteLogo"
          src={images.CompleteImage}
          alt="complete logo"
        />
      ) : (
        <TaskItemCardActions />
      )}
    </div>
  )
}

TaskItemCard.propTypes = {
  complete: PropTypes.bool,
  sub: PropTypes.string,
  houseName: PropTypes.string,
  houseId: PropTypes.string,
  token: PropTypes.string,
  taskItem: PropTypes.shape({ taskItemId: PropTypes.string }),
  taskItemId: PropTypes.string,
  date: PropTypes.string,
  taskTypeId: PropTypes.string,
  taskType: PropTypes.shape({
    taskTypeId: PropTypes.string,
    points: PropTypes.string,
    value: PropTypes.string,
  }),
  points: PropTypes.number,
  timeLength: PropTypes.number,
  setTaskTypeToSchedule: PropTypes.func,
  setShowPickUpTaskModal: PropTypes.func,
  pickUpTaskItem: PropTypes.func,
  completeTaskItem: PropTypes.func,
}
export default TaskItemCard
