import React, { useState } from 'react'
import PropTypes from 'prop-types'
import createTaskTypeModal from './Modals/CreateTaskTypeModal.jsx'
import scheduleTaskFromTaskTypeModal from './Modals/ScheduleTaskFromTaskType.jsx'
import TaskTypeCard from './TaskTypeCard/TaskTypeCard.jsx'

const TaskTypes = ({ taskTypes, createTaskType, sub, token, createTask }) => {
  const [showCreateTaskTypeModal, setShowCreateTaskTypeModal] = useState(false)
  const [taskTypeToSchedule, setTaskTypeToSchedule] = useState({
    taskTypeId: '',
  })
  const [
    showScheduleTaskFromTaskTypeModal,
    setShowScheduleTaskFromTaskTypeModal,
  ] = useState(false)

  const TaskTypesHeader = () => (
    <div className="TaskTypesHeader">
      <div className="TaskTypeHeaderLeftPlaceholder"></div>
      <h2 className="TaskTypesHeaderTitle">Task Types</h2>
      <div className="TaskTypesHeaderButtons">
        <button
          onClick={() => {
            setShowCreateTaskTypeModal(true)
          }}
        >
          New Task
        </button>
      </div>
    </div>
  )

  const NoTaskTypeDisplay = () => (
    <h3 className="NoTaskTypesDisplay">Create a task type to get started!</h3>
  )

  const TaskTypeCards = () => (
    <div className="TaskTypeCards">
      {taskTypes.map((taskType) => (
        <TaskTypeCard
          key={taskType.taskTypeId}
          {...{
            ...taskType,
            setTaskTypeToSchedule,
            setShowScheduleTaskFromTaskTypeModal,
          }}
        />
      ))}
    </div>
  )

  const CreateTaskTypeModal = () =>
    createTaskTypeModal({
      setShowModal: setShowCreateTaskTypeModal,
      showModal: showCreateTaskTypeModal,
      createTaskType,
      sub,
      token,
    })

  const ScheduleTaskFromTaskTypeModal = () =>
    scheduleTaskFromTaskTypeModal({
      setShowModal: setShowScheduleTaskFromTaskTypeModal,
      showModal: showScheduleTaskFromTaskTypeModal,
      taskType: taskTypeToSchedule,
      createTask,
      sub,
      token,
    })

  return (
    <div className="TaskTypesSection">
      <CreateTaskTypeModal />
      <ScheduleTaskFromTaskTypeModal />
      <TaskTypesHeader />
      {taskTypes.length ? <TaskTypeCards /> : <NoTaskTypeDisplay />}
      <hr className="FlexHorizontalBreak" />
    </div>
  )
}

TaskTypes.propTypes = {
  taskTypes: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  property: PropTypes.string,
  name: PropTypes.string,
  taskTypeId: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  sub: PropTypes.string,
  token: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  points: PropTypes.number,
  timeLength: PropTypes.number,
  placeholder: PropTypes.string,
  createTaskType: PropTypes.func,
  createTask: PropTypes.func,
}
export default TaskTypes
