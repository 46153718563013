import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'
import { Link, useNavigate } from 'react-router-dom'
import images from '../images'
import Modal from './Modal.jsx'
import UsersRequests from '../containers/UsersRequest'

const HouseUserBelongsTo = ({ houseName, houseId }) => (
  <Link className="HouseDiv" to={`/house/${houseId}`}>
    <img className="UsersHouseLogo" src={`house.png`} alt="house logo" />
    <h3 className="UsersHouseName">{houseName}</h3>
  </Link>
)

const HousesUserBelongsTo = ({ usersRequests, houses }) => {
  const navigate = useNavigate()
  const [showRequestsModal, setShowRequestsModal] = useState(false)

  const AddHousePlaceholder = () => (
    <img
      onClick={() => navigate('/addHouse')}
      className="AddUserLogo"
      src={images.AddHouseImage}
      alt="house logo"
    />
  )

  const HouseUserBelongsToButtons = () => (
    <div className="HousesUserBelongsToButtons">
      <img
        onClick={() => navigate('/addHouse')}
        className="HousesUserBelongsToButtonLogo"
        src={`plus.png`}
        alt="plus logo"
      />
      <img
        onClick={() => setShowRequestsModal(true)}
        className="HousesUserBelongsToButtonLogo"
        src={
          usersRequests.length ? images.MailNotificationImage : images.MailImage
        }
        alt="mail logo"
      />
      <img
        className="HousesUserBelongsToButtonLogo"
        src={`send.png`}
        alt="send logo"
      />
    </div>
  )

  const HousesUserBelongsTo = () => (
    <div className="HousesUserBelongsToHouses">
      {houses.map((house) => (
        <HouseUserBelongsTo key={uuid()} {...house} />
      ))}
      {!houses.length && <AddHousePlaceholder />}
    </div>
  )

  const RequestsModal = () => (
    <Modal showModal={showRequestsModal} setShowModal={setShowRequestsModal}>
      <UsersRequests />
    </Modal>
  )
  return (
    <div className="HousesUserBelongsToSectionDiv">
      <div className="HousesUserBelongsToSection">
        {RequestsModal()}
        <HousesUserBelongsTo />
        <HouseUserBelongsToButtons />
      </div>
    </div>
  )
}

HouseUserBelongsTo.propTypes = {
  houseName: PropTypes.string,
  houseId: PropTypes.string,
}

HousesUserBelongsTo.propTypes = {
  usersRequests: PropTypes.arrayOf(
    PropTypes.shape({ houseName: PropTypes.string }),
  ),
  getUsersRequests: PropTypes.func,

  houses: PropTypes.arrayOf(PropTypes.shape({ houseName: PropTypes.string })),
  house: PropTypes.shape({
    houseName: PropTypes.string,
    houseId: PropTypes.string,
  }),
  sub: PropTypes.string,
  token: PropTypes.string,
  createHouse: PropTypes.func,
  getHousesUserBelongsTo: PropTypes.func,
}
export default HousesUserBelongsTo
