import api from '../api'
import createAlert from '../createAlert'

export default function getUsersRequests({ sub, token }) {
  return async (dispatch) => {
    try {
      const { requests: usersRequests } = await api.getUsersRequests({
        sub,
        token,
      })
      dispatch({ type: 'USERS_REQUESTS_UPLOADED', usersRequests })
      return true
    } catch (error) {
      dispatch({
        type: 'ADD_ALERT',
        alert: createAlert({ message: error.message, type: 'error' }),
      })
      return false
    }
  }
}
