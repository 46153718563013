import { connect } from 'react-redux'
import User from '../pages/User.jsx'
import getUser from '../actions/getUser'
import getHousesUserBelongsTo from '../actions/getHousesUserBelongsTo.js'
import createHouse from '../actions/createHouse.js'
import completeTaskItem from '../actions/completeTaskItem.js'
const mapStateToProps = (state) => {
  const { token, sub } = state.app
  const {
    user,
    houses,
    incompleteTaskItems,
    completeTaskItems,
    taskTypesMap,
    houseNames,
  } = state.user
  return {
    houseNames,
    token,
    incompleteTaskItems,
    completeTaskItems,
    taskTypesMap,
    sub,
    user,
    houses,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getUser: ({ token, sub }) => dispatch(getUser({ token, sub })),
  createHouse: ({ token, sub, houseName }) =>
    dispatch(createHouse({ token, sub, houseName })),
  getHousesUserBelongsTo: ({ token, sub }) =>
    dispatch(getHousesUserBelongsTo({ token, sub })),
  completeTaskItem: ({ token, sub, taskItemId, houseId }) =>
    dispatch(completeTaskItem({ token, sub, taskItemId, houseId })),
})

const retVal = connect(mapStateToProps, mapDispatchToProps)(User)

export default retVal
