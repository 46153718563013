import React from 'react'
import images from '../images'

const Loading = () => {
  return (
    <div className="App">
      <header className="App-header">
        <img src={images.LoadingImage} className="App-logo" alt="logo" />
      </header>
    </div>
  )
}

export default Loading
