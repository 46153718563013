import { connect } from 'react-redux'
import Alerts from '../components/Alerts.jsx'
import deleteAlert from '../actions/deleteAlert'

const mapStateToProps = (state) => {
  const { alerts } = state.alerts
  return {
    alerts,
  }
}

const mapDispatchToProps = (dispatch) => ({
  deleteAlert: ({ alertId }) => dispatch(deleteAlert({ alertId })),
})

const retVal = connect(mapStateToProps, mapDispatchToProps)(Alerts)

export default retVal
