import { connect } from 'react-redux'
import AddHouse from '../pages/AddHouse.jsx'
import getUser from '../actions/getUser'
import getHousesUserBelongsTo from '../actions/getHousesUserBelongsTo.js'
import createHouse from '../actions/createHouse.js'

const mapStateToProps = (state) => {
  const { token, sub } = state.app
  const { user, houses } = state.user
  return {
    token,
    sub,
    user,
    houses,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getUser: ({ token, sub }) => dispatch(getUser({ token, sub })),
  createHouse: ({ token, sub, houseName }) =>
    dispatch(createHouse({ token, sub, houseName })),
  getHousesUserBelongsTo: ({ token, sub }) =>
    dispatch(getHousesUserBelongsTo({ token, sub })),
})

const retVal = connect(mapStateToProps, mapDispatchToProps)(AddHouse)

export default retVal
