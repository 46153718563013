import React from 'react'
import PropTypes from 'prop-types'
import images from '../../images'

const TaskTypeProperty = ({ property, value }) => (
  <div className="TaskTypeCardProperty">
    <img
      className="TaskTypeCardPropertyLogo"
      src={property === 'points' ? images.BadgeImage : images.ClockImage}
      alt="logo"
    />
    <h4 className="TaskTypeCardPropertyValue">{value}</h4>
  </div>
)

TaskTypeProperty.propTypes = {
  property: PropTypes.string,
  value: PropTypes.number,
}
export default TaskTypeProperty
