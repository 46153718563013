import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import images from '../images'

const LoginPage = () => {
  const { loginWithRedirect } = useAuth0()

  const Icon = () => (
    <div className="LoginBoxIconDiv">
      <img
        className="LoginBoxIconDivImg"
        src={images.HousematesImage}
        alt="coder logo"
      />
    </div>
  )

  const LoginButton = () => (
    <button className="LoginBoxButton" onClick={() => loginWithRedirect()}>
      Log In
    </button>
  )

  return (
    <div className="Login">
      <div className="LoginBox">
        <h2>Housemates</h2>
        <Icon />
        <LoginButton />
      </div>
    </div>
  )
}

export default LoginPage
