import api from '../api'
import createAlert from '../createAlert'
export default function getAllUsers({ token, sub }) {
  return async (dispatch) => {
    try {
      const { users } = await api.getAllUsers({ token, sub })
      dispatch({ type: 'ALL_USERS_UPLOADED', users })
      return true
    } catch (error) {
      dispatch({
        type: 'ADD_ALERT',
        alert: createAlert({ message: error.message, type: 'error' }),
      })
      return false
    }
  }
}
