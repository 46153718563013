import api from '../api'
import createAlert from '../createAlert'

export default function createTaskType({ token, sub, houseId, taskTypeData }) {
  return async (dispatch) => {
    try {
      const taskType = await api.createTaskType({
        token,
        sub,
        houseId,
        taskTypeData,
      })
      dispatch({
        type: 'TASK_TYPE_CREATED',
        taskType: { ...taskTypeData, houseId, ...taskType },
      })
      return true
    } catch (error) {
      dispatch({
        type: 'ADD_ALERT',
        alert: createAlert({ message: error.message, type: 'error' }),
      })
      return false
    }
  }
}
