import { useParams } from 'react-router'
import React, { useState } from 'react'
import Modal from '../Modal.jsx'
import images from '../../images'
import PropTypes from 'prop-types'
const CreateTaskTypeModal = ({
  setShowModal,
  showModal,
  token,
  sub,
  createTaskType,
}) => {
  const { houseId } = useParams()
  const [creatingTaskType, setCreatingTaskType] = useState(false)
  const [taskType, setTaskType] = useState({
    name: '',
    points: 1,
    timeLength: 5,
    description: '',
  })

  const CreateTaskTypePropertyInput = ({
    property,
    name,
    min,
    max,
    type = 'string',
    placeholder,
  }) => (
    <div className="CreateTaskTypeProperty">
      <h3 className="CreateTaskTypePropertyName">{name}</h3>
      <div className="CreateTaskTypePropertyInput">
        {property === 'description' ? (
          <textarea id="CreateTaskTypeDescriptionText" />
        ) : (
          <input
            className="CreateTaskTypePropertyInputText"
            type={type}
            min={min}
            max={max}
            placeholder={placeholder}
            value={taskType[property]}
            onChange={(e) =>
              setTaskType({ ...taskType, [property]: e.target.value })
            }
          />
        )}
      </div>
    </div>
  )

  const CreateTaskTypeModalContent = () => (
    <div className="CreateTaskTypeModalContent">
      <h1>Create a New Task Type</h1>
      {CreateTaskTypePropertyInput({
        property: 'name',
        name: 'Name',
        placeholder: 'Walk the dog',
      })}
      <div className="CreateTaskTypeModalContentRow">
        {CreateTaskTypePropertyInput({
          property: 'timeLength',
          name: 'Time Length',
          type: 'number',
          min: '1',
          max: '10000',
          placeholder: '30 (minutes)',
        })}
        {CreateTaskTypePropertyInput({
          property: 'points',
          name: 'Points',

          type: 'number',
          min: '1',
          max: '5',
          placeholder: '5',
        })}
      </div>
      {CreateTaskTypePropertyInput({
        property: 'description',
        name: 'Description',
        placeholder: 'Take the dog out \nfor a nice long waok',
      })}
      &nbsp;
      <div>
        <button
          className="NameFormButton"
          type="submit"
          onClick={async () => {
            setCreatingTaskType(true)
            await createTaskType({
              sub,
              token,
              houseId,
              taskTypeData: {
                ...taskType,
                description: document.getElementById(
                  'CreateTaskTypeDescriptionText',
                ).value,
              },
            })
            setCreatingTaskType(false)
            setShowModal(false)
          }}
        >
          Create Task Type
        </button>
      </div>
    </div>
  )
  return (
    <Modal setShowModal={setShowModal} showModal={showModal}>
      {creatingTaskType ? (
        <img src={images.LoadingImage} className="Loadinglogo" alt="logo" />
      ) : (
        CreateTaskTypeModalContent()
      )}
    </Modal>
  )
}

CreateTaskTypeModal.propTypes = {
  property: PropTypes.string,
  name: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  sub: PropTypes.string,
  token: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  points: PropTypes.number,
  timeLength: PropTypes.number,
  placeholder: PropTypes.string,
  createTaskType: PropTypes.func,
  setShowModal: PropTypes.func,
  showModal: PropTypes.bool,
}

export default CreateTaskTypeModal
