import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from '../Modal.jsx'
import images from '../../images/index.js'

const PickUpTaskModal = ({
  taskTypeProperties,
  taskItem,
  sub,
  showModal,
  setShowModal,
  pickUpTaskItem,
  token,
}) => {
  const [loading, setLoading] = useState(false)

  const modalContent = (
    <>
      <h1>Are you sure you want to pick up this task? </h1>
      {taskTypeProperties}
      <button
        onClick={async () => {
          setLoading(true)
          await pickUpTaskItem({ token, taskItem, sub })
          setLoading(false)
          setShowModal(false)
        }}
      >
        Yes
      </button>
      <button
        onClick={() => {
          setShowModal(false)
        }}
      >
        No
      </button>
    </>
  )
  return (
    <Modal showModal={showModal} setShowModal={setShowModal}>
      {loading ? (
        <img src={images.LoadingImage} className="Loadinglogo" alt="logo" />
      ) : (
        modalContent
      )}
    </Modal>
  )
}

PickUpTaskModal.propTypes = {
  taskTypeProperties: PropTypes.node,
  taskItem: PropTypes.shape({ taskItemId: PropTypes.string }),
  token: PropTypes.string,
  sub: PropTypes.string,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  pickUpTaskItem: PropTypes.func,
}
export default PickUpTaskModal
