import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

const Modal = ({ children, showModal, setShowModal }) => {
  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) setShowModal(false)
  }

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyDown)
    // useEffect cleanup function
    // get rid of event listener right after
    return function cleanup() {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown)
    }
  })
  return (
    <div
      className={`Modal${showModal ? ' Show' : ''}`}
      onClick={() => setShowModal(false)}
    >
      <div className="ModalContent" onClick={(e) => e.stopPropagation()}>
        <div className="ModalContentClose">
          <span
            className="ModalContentCloseSpan"
            onClick={() => setShowModal(false)}
          >
            &times;
          </span>
        </div>
        {children}
      </div>
    </div>
  )
}

export default Modal

Modal.propTypes = {
  children: PropTypes.node,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
}
