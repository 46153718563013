import { connect } from 'react-redux'
import UserHeader from '../components/UserHeader.jsx'
import getUser from '../actions/getUser'

const mapStateToProps = (state) => {
  const { token, sub } = state.app

  const { user } = state.user
  return {
    user,
    token,
    sub,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getUser: ({ token, sub }) => dispatch(getUser({ token, sub })),
})
const retVal = connect(mapStateToProps, mapDispatchToProps)(UserHeader)

export default retVal
