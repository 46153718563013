import api from '../api'
import createAlert from '../createAlert'
export default function getAllHouses({ token, sub }) {
  return async (dispatch) => {
    try {
      const { houses } = await api.getAllHouses({ token, sub })
      const { houses: userHouses } = await api.getHousesUserBelongsTo({
        token,
        sub,
      })

      const userHouseIds = userHouses.map(({ houseId }) => houseId)

      dispatch({
        type: 'ALL_HOUSES_UPLOADED',
        houses: houses.filter(({ houseId }) => !userHouseIds.includes(houseId)),
      })
      return true
    } catch (error) {
      dispatch({
        type: 'ADD_ALERT',
        alert: createAlert({ message: error.message, type: 'error' }),
      })
      return false
    }
  }
}
