const users = (
  state = {
    users: [],
  },
  action,
) => {
  switch (action.type) {
    case 'ALL_USERS_UPLOADED': {
      return Object.assign({}, state, { users: action.users })
    }

    default: {
      return state
    }
  }
}

export default users
