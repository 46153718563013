import React from 'react'
import PropTypes from 'prop-types'
import TaskTypeProperties from './TaskTypeProperties.jsx'
const TaskTypeCard = ({
  name,
  points,
  timeLength,
  taskTypeId,
  setTaskTypeToSchedule,
  setShowScheduleTaskFromTaskTypeModal,
}) => {
  return (
    <div className="TaskTypeCard">
      <TaskTypeProperties {...{ name, points, timeLength, taskTypeId }} />
      <div className="TaskTypeCardActions">
        <button
          className="TaskTypeCardButton"
          onClick={() => {
            setTaskTypeToSchedule({ name, points, timeLength, taskTypeId })
            setShowScheduleTaskFromTaskTypeModal(true)
          }}
        >
          Schedule
        </button>
      </div>
    </div>
  )
}

TaskTypeCard.propTypes = {
  name: PropTypes.string,
  taskTypeId: PropTypes.string,

  points: PropTypes.number,
  timeLength: PropTypes.number,
  setTaskTypeToSchedule: PropTypes.func,
  setShowScheduleTaskFromTaskTypeModal: PropTypes.func,
}
export default TaskTypeCard
