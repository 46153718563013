import api from '../api'
import createAlert from '../createAlert'
export default function getHouse({ token, houseId }) {
  return async (dispatch) => {
    try {
      const { house } = await api.getHouse({ token, houseId })
      const { users } = await api.getHouseUsers({ houseId, token })
      const { taskTypes } = await api.getHouseTaskTypes({ houseId, token })
      const { tasks } = await api.getHouseTasks({ houseId, token })
      dispatch({ type: 'HOUSE_UPLOADED', house, users, taskTypes, tasks })
      return true
    } catch (error) {
      if (error.message !== 'No House Found') {
        dispatch({
          type: 'ADD_ALERT',
          alert: createAlert({ message: error.message, type: 'error' }),
        })
      }
      return false
    }
  }
}
