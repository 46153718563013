import { connect } from 'react-redux'
import House from '../pages/House.jsx'
import getHouse from '../actions/getHouse'

const mapStateToProps = (state) => {
  const { token, sub } = state.app
  const { house, users, taskTypes } = state.house
  return {
    taskTypes,
    token,
    sub,
    house,
    users,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getHouse: ({ token, houseId }) => dispatch(getHouse({ token, houseId })),
})

const retVal = connect(mapStateToProps, mapDispatchToProps)(House)

export default retVal
