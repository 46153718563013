import React from 'react'
import images from '../images'
import PropTypes from 'prop-types'

const ImageUploader = ({ selectedImage, setSelectedImage }) => {
  return (
    <div className="ImageUploader">
      <h1 className="ImageUploaderTitle">Upload Image</h1>
      {selectedImage && (
        <img
          className="ImageUploaderImage"
          alt="not fount"
          src={URL.createObjectURL(selectedImage)}
        />
      )}
      <br />
      {selectedImage && (
        <img
          className="ImageUploaderRemoveImage"
          alt="remove image"
          onClick={() => setSelectedImage(null)}
          src={images.RemoveImage}
        />
      )}
      <br />

      <br />
      <button
        onClick={() => document.getElementById('image-uploader-input').click()}
      >
        <input
          onChange={(event) => {
            console.log(event.target.files[0])
            setSelectedImage(event.target.files[0])
          }}
          onClick={(event) => (event.target.value = '')}
          className="ImageUploaderInput"
          type="file"
          name="file"
          id="image-uploader-input"
        />
        Add Profile Photo
      </button>
    </div>
  )
}

ImageUploader.propTypes = {
  selectedImage: PropTypes.node,
  setSelectedImage: PropTypes.func,
}
export default ImageUploader
