import { connect } from 'react-redux'
import Leaderboard from '../components/Leaderboard.jsx'

const mapStateToProps = (state) => {
  const { token, sub } = state.app
  const { users } = state.house
  return {
    token,
    sub,
    users,
  }
}

const mapDispatchToProps = () => ({})

const retVal = connect(mapStateToProps, mapDispatchToProps)(Leaderboard)

export default retVal
