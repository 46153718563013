import { combineReducers } from 'redux'
import app from './app'
import user from './user'
import users from './users'
import alerts from './alerts'
import house from './house'
import houses from './houses'
import requests from './requests'

const reducers = combineReducers({
  app,
  user,
  users,
  alerts,
  house,
  houses,
  requests,
})

export default reducers
