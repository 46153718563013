import React from 'react'
import Loading from './Loading.jsx'
import { useAuth0 } from '@auth0/auth0-react'
import User from '../containers/User'
import SignUp from '../containers/SignUp'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { useEffect } from 'react'
import Auth0Config from '../config.json'
import LoginPage from '../pages/LoginPage.jsx'
import PropTypes from 'prop-types'
import AddHouse from '../containers/AddHouse.js'
import House from '../containers/House'
import AddUserToHouse from '../containers/AddUserToHouse.js'
import HousesLookup from '../containers/HousesLookup'

const App = ({ uploadMetadata, token: receivedToken }) => {
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    user,
  } = useAuth0()

  useEffect(() => {
    const getAndUploadMetadata = async () => {
      const token = await getAccessTokenSilently({
        audience: Auth0Config.audience,
      })
      uploadMetadata({ token, sub: user.sub })

      //show an error if there is an issue getting the token
    }
    if (isAuthenticated && !receivedToken) {
      getAndUploadMetadata()
    }
  })

  if (!isAuthenticated && !isLoading) {
    return <LoginPage />
  }

  if (isLoading || !receivedToken) {
    return <Loading />
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<User />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/addHouse" element={<AddHouse />} />
        <Route path="/housesLookup" element={<HousesLookup />} />
        <Route path="/house/:houseId" element={<House />} />
        <Route path="/houses/:houseId/addUser" element={<AddUserToHouse />} />
      </Routes>
    </Router>
  )
}

export default App

App.propTypes = {
  uploadMetadata: PropTypes.func,
  token: PropTypes.string,
}
