const user = (
  state = {
    alerts: [],
  },
  action,
) => {
  switch (action.type) {
    case 'ADD_ALERT': {
      const alerts = [...state.alerts]
      alerts.push(action.alert)
      return Object.assign({}, state, { alerts })
    }

    case 'DELETE_ALERT': {
      const alerts = [...state.alerts]

      return Object.assign({}, state, {
        alerts: alerts.filter(({ alertId }) => alertId !== action.alertId),
      })
    }

    default: {
      return state
    }
  }
}

export default user
