import HousematesImage from './housemates.png'
import LogoutImage from './logout.png'
import ProfileImage from './profile.png'
import LoadingImage from './loading.png'
import PlusImage from './plus.png'
import AddHouseImage from './addhouse.png'
import HouseImage from './house.png'
import SearchImage from './search.png'
import SendImage from './send.png'
import MailNotificationImage from './mailnotification.png'
import MailImage from './mail.png'
import AddUserImage from './adduser.png'
import BadgeImage from './badge.png'
import ClockImage from './clock.png'
import CompleteImage from './complete.png'
import RemoveImage from './remove.png'

export default {
  MailImage,
  MailNotificationImage,
  HouseImage,
  HousematesImage,
  LogoutImage,
  ProfileImage,
  LoadingImage,
  PlusImage,
  AddHouseImage,
  SearchImage,
  SendImage,
  AddUserImage,
  BadgeImage,
  RemoveImage,
  CompleteImage,
  ClockImage,
}
