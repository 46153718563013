import React, { useEffect, useState } from 'react'
import UserHeader from '../containers/UserHeader'
import HousesUserBelongsTo from '../containers/HousesUserBelongsTo'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router'
import LoadingWithHeader from './LoadingWithHeader.jsx'
import TaskItemCard from '../components/TaskItemCard/TaskItemCard.jsx'
const User = ({
  token,
  sub,
  user,
  getUser,
  getHousesUserBelongsTo,
  incompleteTaskItems,
  completeTaskItems,
  completeTaskItem,
  houseNames,
}) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(user.username === '')

  useEffect(() => {
    const checkForUser = async () => {
      const userExists = await getUser({ token, sub })
      await getHousesUserBelongsTo({ token, sub })
      if (!userExists) navigate('/signup')
      setLoading(false)
    }

    if (loading) {
      setLoading(true)
      checkForUser()
    }
  }, [getUser, token, sub, user, user.username])

  if (loading) {
    return <LoadingWithHeader message="Looking for user" />
  }
  const UsersIncompleteTasks = () => (
    <div className="UsersIncompleteTasks">
      <h3>Incomplete Tasks</h3>

      <div className="TaskItemCards">
        {incompleteTaskItems.length ? (
          incompleteTaskItems.map((taskItem) => (
            <TaskItemCard
              completeTaskItem={completeTaskItem}
              key={taskItem.taskItemId}
              {...taskItem}
              token={token}
              houseName={houseNames[taskItem.houseId]}
            />
          ))
        ) : (
          <h3 className="NoTasksDisplay">No incomplete tasks</h3>
        )}
      </div>
    </div>
  )

  const UsersCompleteTasks = () => (
    <div className="UsersCompleteTasks">
      <h3>Complete Tasks</h3>
      <div className="TaskItemCards">
        {completeTaskItems.map((taskItem) => (
          <TaskItemCard
            key={taskItem.taskItemId}
            {...taskItem}
            houseName={houseNames[taskItem.houseId]}
          />
        ))}
      </div>
    </div>
  )
  return (
    <div className="UserPage">
      <UserHeader />

      <HousesUserBelongsTo />
      <hr className="FlexHorizontalBreak" />

      <UsersIncompleteTasks />
      <hr className="FlexHorizontalBreak" />

      <UsersCompleteTasks />
    </div>
  )
}

export default User

User.propTypes = {
  completeTaskItems: PropTypes.arrayOf(),
  incompleteTaskItems: PropTypes.arrayOf(),
  houseNames: PropTypes.arrayOf(),
  sub: PropTypes.string,
  token: PropTypes.string,
  user: PropTypes.shape({
    username: PropTypes.string,
    profilePicture: PropTypes.string,
  }),
  getUser: PropTypes.func,
  getHousesUserBelongsTo: PropTypes.func,
  completeTaskItem: PropTypes.func,
}
