const user = (
  state = {
    house: {
      username: '',
    },
    users: [],
    taskTypes: [],
    tasks: [],
    unassignedTaskItems: [],
    taskTypesMap: {},
  },
  action,
) => {
  switch (action.type) {
    case 'USER_PICKED_UP_TASK_ITEM': {
      const unassignedTaskItems = [...state.unassignedTaskItems].filter(
        ({ taskItemId }) => taskItemId !== action.taskItem.taskItemId,
      )
      return Object.assign({}, state, { unassignedTaskItems })
    }
    case 'HOUSE_UPLOADED': {
      const taskTypesMap = {}
      Object.values(action.taskTypes).forEach(
        (taskType) => (taskTypesMap[taskType.taskTypeId] = taskType),
      )
      return Object.assign({}, state, {
        house: action.house,
        users: action.users,
        taskTypes: action.taskTypes,
        tasks: action.tasks,
        unassignedTaskItems: action.tasks.reduce(
          (agg, currTask) =>
            agg.concat(
              currTask.taskItems.filter(({ assignedTo }) => !assignedTo),
            ),
          [],
        ),
        taskTypesMap,
      })
    }

    case 'TASK_TYPE_CREATED': {
      const taskTypes = [...state.taskTypes]
      taskTypes.push(action.taskType)
      const taskTypesMap = { ...state.taskTypesMap }
      taskTypesMap[action.taskType.taskTypeId] = action.taskType
      return Object.assign({}, state, {
        taskTypes,
        taskTypesMap,
      })
    }
    case 'TASK_CREATED': {
      const tasks = [...state.tasks]
      tasks.push(action.task)
      const unassignedTaskItems = [...state.unassignedTaskItems].concat(
        ...action.task.taskItems,
      )
      return Object.assign({}, state, {
        tasks,
        unassignedTaskItems,
      })
    }

    default: {
      return state
    }
  }
}

export default user
