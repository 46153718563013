import { useParams } from 'react-router'
import React, { useState } from 'react'
import Modal from '../Modal.jsx'
import images from '../../images'
import DateTimePicker from 'react-datetime-picker'
import TaskTypeProperties from '../TaskTypeCard/TaskTypeProperties.jsx'

import PropTypes from 'prop-types'
const ScheduleTaskTypeModal = ({
  setShowModal,
  showModal,
  token,
  sub,
  createTask,
  taskType,
}) => {
  const { houseId } = useParams()
  const [creatingTask, setCreatingTask] = useState(false)
  const [task, setTask] = useState({
    date: new Date(),
  })

  const ScheduleTaskPropertyInput = ({
    property,
    name,
    min,
    max,
    type = 'string',
    placeholder,
  }) => {
    let propertyInput = <></>

    if (property === 'description') {
      propertyInput = <textarea id="ScheduleTaskDescriptionText" />
    } else if (property === 'date') {
      propertyInput = (
        <div>
          <DateTimePicker
            onChange={(date) => setTask({ ...task, date: new Date(date) })}
            value={task.date}
          />
        </div>
      )
    } else {
      propertyInput = (
        <input
          className="ScheduleTaskPropertyInputText"
          type={type}
          min={min}
          max={max}
          placeholder={placeholder}
          value={task[property]}
          onChange={(e) => setTask({ ...task, [property]: e.target.value })}
        />
      )
    }

    return (
      <div className="ScheduleTaskProperty">
        <h3 className="ScheduleTaskPropertyName">{name}</h3>
        <div className="ScheduleTaskPropertyInput">{propertyInput}</div>
      </div>
    )
  }

  const ScheduleTaskModalContent = () => (
    <div className="ScheduleTaskModalContent">
      <h1>Schedule a Task </h1>
      <TaskTypeProperties {...taskType} />
      {ScheduleTaskPropertyInput({
        property: 'date',
        name: 'Date',
        placeholder: new Date().toString(),
      })}
      &nbsp;
      <div>
        <button
          className="NameFormButton"
          type="submit"
          onClick={async () => {
            setCreatingTask(true)
            await createTask({
              sub,
              token,
              houseId,
              taskData: {
                ...task,
                ...taskType,
              },
            })
            setCreatingTask(false)
            setShowModal(false)
          }}
        >
          Create Task
        </button>
      </div>
    </div>
  )
  return (
    <Modal setShowModal={setShowModal} showModal={showModal}>
      {creatingTask ? (
        <img src={images.LoadingImage} className="Loadinglogo" alt="logo" />
      ) : (
        ScheduleTaskModalContent()
      )}
    </Modal>
  )
}

ScheduleTaskTypeModal.propTypes = {
  property: PropTypes.string,
  name: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  sub: PropTypes.string,
  token: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  points: PropTypes.number,
  timeLength: PropTypes.number,
  placeholder: PropTypes.string,
  taskType: PropTypes.shape({ taskTypeId: PropTypes.string }),
  createTask: PropTypes.func,
  setShowModal: PropTypes.func,
  showModal: PropTypes.bool,
}

export default ScheduleTaskTypeModal
