import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import images from '../images'
import { useNavigate } from 'react-router'

const House = ({ houseName }) => (
  <div className="UserRequestHouseDiv">
    <img className="UsersHouseLogo" src={`house.png`} alt="house logo" />
    <h3>{houseName}</h3>
  </div>
)

const UserRequests = ({
  usersRequests,
  getUsersRequests,
  respondToHouseRequest,
  sub,
  token,
}) => {
  const navigate = useNavigate()
  const [showConfirmContent, setShowConfirmContent] = useState(false)
  const [userRequestData, setUserRequestData] = useState({})
  useEffect(() => {
    getUsersRequests({ sub, token })
  }, [])

  const [responding, setResponding] = useState(false)

  const ConfirmationContent = () => (
    <div>
      <h1>Are you sure you want to join {userRequestData.houseName}</h1>
      <button
        onClick={async () => {
          setResponding(true)
          await respondToHouseRequest(userRequestData)
          navigate('/house/' + userRequestData.houseId)
          setResponding(false)
        }}
      >
        Yes
      </button>
      <button
        onClick={() => {
          setShowConfirmContent(false)
          setUserRequestData({})
        }}
      >
        No
      </button>
    </div>
  )

  const UsersRequestHouse = ({ house }) => (
    <div className="UsersRequestsHouse">
      <House key={house.houseId} {...house} />
      <div className="UsersRequestsHouseAddLogoDiv">
        <img
          onClick={async () => {
            setUserRequestData({ sub, ...house, token, approve: true })
            setShowConfirmContent(true)
          }}
          className={
            responding
              ? 'UsersRequestsHouseAddLogo'
              : 'UsersRequestsHouseAddLogo'
          }
          src={responding ? images.LoadingImage : images.PlusImage}
        />
        <p> </p>
      </div>
    </div>
  )

  if (!usersRequests.length) return <p>No requests for this user</p>

  const UserRequest = () => (
    <div className="UsersRequests">
      {usersRequests.map((house) => (
        <UsersRequestHouse key={house.houseId} {...{ house }} />
      ))}
    </div>
  )
  return showConfirmContent ? <ConfirmationContent /> : <UserRequest />
}

House.propTypes = {
  houseName: PropTypes.string,
  houseId: PropTypes.string,
}
UserRequests.propTypes = {
  usersRequests: PropTypes.arrayOf(
    PropTypes.shape({ houseName: PropTypes.string }),
  ),
  getUsersRequests: PropTypes.func,
  respondToHouseRequest: PropTypes.func,
  house: PropTypes.shape({
    houseName: PropTypes.string,
    houseId: PropTypes.string,
  }),
  sub: PropTypes.string,
  token: PropTypes.string,
}
export default UserRequests
