import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect, useState } from 'react'
import Loading from '../components/Loading.jsx'
import ImageUploader from '../components/ImageUploader.jsx'
import PropTypes from 'prop-types'
import images from '../images/index.js'

const SignUp = ({ token, sub, createUser, getUser, user }) => {
  const { user: Auth0User = { name: '' } } = useAuth0()
  const [checkingForUser, setCheckingForUser] = useState(true)
  const [username, setUsername] = useState(Auth0User.name)
  const [selectedImage, setSelectedImage] = useState(null)
  useEffect(() => {
    const checkForUser = async () => {
      const userExists = await getUser({ token, sub })
      if (userExists) window.location = '/'
      else setCheckingForUser(false)
    }

    if (!(token || sub)) {
      window.location = '/'
    }
    if (!user.name) {
      checkForUser()
    }
  }, [token, sub, getUser, user])
  const onSubmit = async () => {
    const userCreated = await createUser({
      token,
      sub,
      username,
      profilePicture: selectedImage,
    })
    if (userCreated) window.location = '/'
  }
  const Icon = () => (
    <img
      className="SignUpBoxIconDivImg"
      src={images.HousematesImage}
      alt="coder logo"
    />
  )

  const NameForm = () => (
    <div className="NameForm">
      <ImageUploader
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
      />

      <div className="NameFormInput">
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        &nbsp;
      </div>
      <button className="NameFormButton" type="submit" onClick={onSubmit}>
        Create Account
      </button>
    </div>
  )

  if (checkingForUser) return <Loading />
  return (
    <div className="SignUp">
      <div className="SignUpBox">
        <h2 className="SignUpTitle">Housemates</h2>
        <Icon />
        <hr className="FlexHorizontalBreak" />
        {NameForm()}
      </div>
    </div>
  )
}

export default SignUp

SignUp.propTypes = {
  sub: PropTypes.string,
  token: PropTypes.string,
  user: PropTypes.shape({ name: PropTypes.string }),
  getUser: PropTypes.func,
  createUser: PropTypes.func,
}
