import React from 'react'
import PropTypes from 'prop-types'
import TaskTypeProperty from './TaskTypeProperty.jsx'

const TaskTypeProperties = ({
  name,
  points,
  timeLength,
  houseId,
  houseName,
}) => (
  <div className="TaskTypeCardAllProperties">
    <h3 className="TaskTypeCardAllPropertiesName">{name}</h3>
    {houseId ? (
      <h4 className="TaskTypeCardAllPropertiesHouse">
        {houseName ? houseName : houseId}
      </h4>
    ) : (
      ''
    )}
    <div className="TaskTypeCardProperties">
      <TaskTypeProperty property="points" value={points} />
      <TaskTypeProperty property="timeLength" value={timeLength} />
    </div>
  </div>
)

TaskTypeProperties.propTypes = {
  name: PropTypes.string,
  houseName: PropTypes.string,
  houseId: PropTypes.string,
  taskTypeId: PropTypes.string,
  points: PropTypes.number,
  timeLength: PropTypes.number,
}
export default TaskTypeProperties
