import React from 'react'
import images from '../images'
import UserHeader from '../containers/UserHeader.js'
import PropTypes from 'prop-types'

const LoadingWithHeader = ({ message }) => {
  return (
    <div className="LoadingWithHeader">
      <UserHeader />
      <header className="LoadingLogoDiv">
        <img src={images.LoadingImage} className="Loadinglogo" alt="logo" />
        {message ? <h1>{message}</h1> : ''}
      </header>
    </div>
  )
}

LoadingWithHeader.propTypes = {
  message: PropTypes.string,
}

export default LoadingWithHeader
