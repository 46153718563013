import api from '../api'
import createAlert from '../createAlert'

export default function createUser({ token, sub, username, profilePicture }) {
  return async (dispatch) => {
    try {
      await api.createUser({ token, sub, username, profilePicture })
      dispatch({
        type: 'USER_UPLOADED',
        user: { username, sub, profilePicture },
      })
      return true
    } catch (error) {
      dispatch({
        type: 'ADD_ALERT',
        alert: createAlert({ message: error.message, type: 'error' }),
      })
      return false
    }
  }
}
