import React, { useEffect, useState } from 'react'
import UserHeader from '../containers/UserHeader.js'
import PropTypes from 'prop-types'
import Modal from '../components/Modal.jsx'
import {
  uniqueNamesGenerator,
  adjectives,
  colors,
  animals,
} from 'unique-names-generator'
import images from '../images/index.js'
import { Link } from 'react-router-dom'
import LoadingWithHeader from './LoadingWithHeader.jsx'

const AddHouse = ({ token, sub, createHouse, getUser, user }) => {
  const randomName = uniqueNamesGenerator({
    dictionaries: [colors, adjectives, animals],
    style: 'capital',
  })
  const [houseName, setHouseName] = useState()
  const [showAddHouseModal, setShowAddHouseModal] = useState()
  const [loading, setLoading] = useState(!user.username)
  useEffect(() => {
    const loadUser = async () => {
      const userExists = await getUser({ token, sub })
      if (!userExists) window.location = '/signup'
      setLoading(false)
    }

    if (loading) {
      loadUser()
    }
  })

  if (loading) {
    return <LoadingWithHeader message="Looking for user" />
  }

  const addHouseModal = () => (
    <Modal setShowModal={setShowAddHouseModal} showModal={showAddHouseModal}>
      <div className="HouseNameForm">
        <div className="HouseNameFormInput">
          <p className="HouseNameFormLabel">House Name</p>&nbsp;
          <input
            type="text"
            placeholder={randomName}
            value={houseName}
            onChange={(e) => setHouseName(e.target.value)}
          />
          &nbsp;
          <button
            className="HouseNameFormButton"
            type="submit"
            onClick={async () => {
              await createHouse({ token, sub, houseName })
              window.location = '/'
            }}
          >
            Create House
          </button>
        </div>
      </div>
    </Modal>
  )

  return (
    <div className="AddHousePage">
      {addHouseModal()}
      <UserHeader />
      <div className="AddHouseButtons">
        <img
          className={
            showAddHouseModal
              ? 'AddHouseButtonsLogoNoHover'
              : 'AddHouseButtonsLogo'
          }
          onClick={() => setShowAddHouseModal(true)}
          src={images.PlusImage}
          alt="plus logo"
        />
        <Link to="/housesLookup">
          <img
            className="AddHouseButtonsLogo"
            src={images.SearchImage}
            alt="search logo"
          />
        </Link>
      </div>
    </div>
  )
}

export default AddHouse

AddHouse.propTypes = {
  sub: PropTypes.string,
  token: PropTypes.string,
  user: PropTypes.shape({ username: PropTypes.string }),
  getUser: PropTypes.func,
  createHouse: PropTypes.func,
}
