import api from '../api'
import createAlert from '../createAlert'

export default function getHouseRequest({ houseId, token }) {
  return async (dispatch) => {
    try {
      const { houseRequests: requests } = await api.getHouseRequests({
        houseId,
        token,
      })
      dispatch({ type: 'ALL_REQUESTS_UPLOADED', requests })
      return true
    } catch (error) {
      dispatch({
        type: 'ADD_ALERT',
        alert: createAlert({ message: error.message, type: 'error' }),
      })
      return false
    }
  }
}
