import api from '../api'
import createAlert from '../createAlert'

export default function pickUpTaskItem({ taskItem, sub, token }) {
  return async (dispatch) => {
    try {
      await api.assignTaskItem({ ...taskItem, sub, token })
      dispatch({
        type: 'USER_PICKED_UP_TASK_ITEM',
        taskItem,
      })
      return true
    } catch (error) {
      dispatch({
        type: 'ADD_ALERT',
        alert: createAlert({ message: error.message, type: 'error' }),
      })
      return false
    }
  }
}
