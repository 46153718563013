import { connect } from 'react-redux'
import HousesUserBelongsTo from '../components/HousesUserBelongsTo.jsx'
import getHousesUserBelongsTo from '../actions/getHousesUserBelongsTo.js'
import createHouse from '../actions/createHouse.js'
import getUsersRequests from '../actions/getUsersRequests.js'

const mapStateToProps = (state) => {
  const { token, sub } = state.app
  const { houses, usersRequests } = state.user
  return {
    token,
    sub,
    houses,
    usersRequests,
  }
}

const mapDispatchToProps = (dispatch) => ({
  createHouse: ({ token, sub, houseName }) =>
    dispatch(createHouse({ token, sub, houseName })),
  getHousesUserBelongsTo: ({ token, sub }) =>
    dispatch(getHousesUserBelongsTo({ token, sub })),
  getUsersRequests: ({ token, sub }) =>
    dispatch(getUsersRequests({ token, sub })),
})

const retVal = connect(mapStateToProps, mapDispatchToProps)(HousesUserBelongsTo)

export default retVal
