import { connect } from 'react-redux'
import Tasks from '../components/Tasks.jsx'
import createTask from '../actions/createTask.js'
import pickUpTaskItem from '../actions/pickUpTaskItem.js'
const mapStateToProps = (state) => {
  const { token, sub } = state.app
  const {
    tasks,
    unassignedTaskItems,
    taskTypesMap,
    house: { houseName },
  } = state.house
  return {
    unassignedTaskItems,
    taskTypesMap,
    token,
    tasks,
    sub,
    houseName,
  }
}

const mapDispatchToProps = (dispatch) => ({
  createTask: ({ token, houseId, taskData }) =>
    dispatch(createTask({ token, houseId, taskData })),
  pickUpTaskItem: ({ token, sub, taskItem }) =>
    dispatch(pickUpTaskItem({ token, sub, taskItem })),
})

const retVal = connect(mapStateToProps, mapDispatchToProps)(Tasks)

export default retVal
