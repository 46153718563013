const app = (
  state = {
    token: undefined,
    sub: undefined,
  },
  action,
) => {
  switch (action.type) {
    case 'METADATA_UPLOADED': {
      return Object.assign({}, state, { token: action.token, sub: action.sub })
    }
    case 'APP_METADATA_UPLOAD_FAILED': {
      return state
    }

    default: {
      return state
    }
  }
}

export default app
