import api from '../api'
import createAlert from '../createAlert'

export default function createHouseRequest({
  type,
  sub,
  username,
  houseId,
  token,
}) {
  return async (dispatch) => {
    try {
      const { houseRequest: request } = await api.createHouseRequest({
        type,
        sub,
        username,
        houseId,
        token,
      })
      dispatch({ type: 'REQUEST_CREATED', request })
      return true
    } catch (error) {
      dispatch({
        type: 'ADD_ALERT',
        alert: createAlert({ message: error.message, type: 'error' }),
      })
      return false
    }
  }
}
