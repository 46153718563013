import React, { useEffect, useState } from 'react'
import UserHeader from '../containers/UserHeader.js'
import PropTypes from 'prop-types'
import { useParams, useNavigate } from 'react-router-dom'
import images from '../images/index.js'
import SmallUser from '../components/SmallUser.jsx'
import Modal from '../components/Modal.jsx'
import LoadingWithHeader from './LoadingWithHeader.jsx'
const AddUserToHouse = ({
  token,
  sub,
  house,
  getAllUsers,
  getHouse,
  createHouseRequest,
  getHouseRequests,
  users,
  requests,
}) => {
  const { houseId } = useParams()
  const navigate = useNavigate()

  const [userToAdd, setUserToAdd] = useState({ username: '' })
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const [loading, setLoading] = useState(
    users.length == 0 && requests.length == 0,
  )
  const [sendingRequest, setSendingRequest] = useState(false)

  // If theres no token/sub, user needs to login/signup
  if (!(token || sub)) {
    navigate('/')
  }
  useEffect(() => {
    const getAllUsersAndRequests = async () => {
      if (!house.houseName || house.houseId !== houseId) {
        const houseExists = await getHouse({ token, houseId })
        if (!houseExists) navigate('/')
      }

      await getHouseRequests({ token, houseId })
      await getAllUsers({ token })
      if (!users) window.location = '/house/' + houseId
      setLoading(false)
    }
    //if there is no house, or the houseid doesn't match the house loaded
    //reload the house
    // check to see if we grabbed the user requests
    if (loading) getAllUsersAndRequests()
  }, [getHouse, getHouseRequests, getAllUsers, users, house])

  if (loading) {
    return <LoadingWithHeader message="Looking for users" />
  }

  const ConfirmationModal = () => (
    <Modal
      showModal={showConfirmationModal}
      setShowModal={(value) => {
        setShowConfirmationModal(value)
        setUserToAdd({ username: '' })
      }}
    >
      {sendingRequest ? (
        <div>loading</div>
      ) : (
        <div>
          <h1>
            {`Are you sure you want to add ${userToAdd.username} to ${house.houseName}`}
          </h1>
          <button
            onClick={async () => {
              setSendingRequest(true)
              await createHouseRequest({
                token,
                type: 'admin',
                ...userToAdd,
                houseId: house.houseId,
              })
              setShowConfirmationModal(false)
              setSendingRequest(false)
            }}
          >
            Add User
          </button>
          <button
            onClick={() => {
              setShowConfirmationModal(false)
              setUserToAdd({ username: '' })
            }}
          >
            Cancel
          </button>
        </div>
      )}
    </Modal>
  )

  const UserToAdd = (user) => (
    <div className="AddUserToHousePageUsersUserDiv">
      <ConfirmationModal />

      <SmallUser key={user.sub} {...user} callerSub={sub} />
      <div className="LeaderboardAddUserDiv">
        <img
          onClick={() => {
            setUserToAdd(user)
            setShowConfirmationModal(true)
          }}
          className="LeaderboardAddUserLogo"
          src={images.PlusImage}
          alt="plus logo"
        />
        <p> </p>
      </div>
    </div>
  )

  const UserRequest = (request) => (
    <div className="AddUserToHousePageUsersUserDiv">
      <ConfirmationModal />
      <div className="AddUserToHousePageUsersSmallUser">
        <SmallUser key={request.sub} {...request} callerSub={sub} />
      </div>
      <div className="LeaderboardAddUserDiv">
        <img
          className="LeaderboardAddUserLogo"
          src={images.SendImage}
          alt="plus logo"
        />
        <p> </p>
      </div>
    </div>
  )

  const UsersToAdd = () =>
    users.length ? (
      <div className="AddUserToHousePageUsers">
        {users.map((user) => (
          <UserToAdd key={user.sub} {...user} />
        ))}
      </div>
    ) : (
      <></>
    )

  const HouseRequests = () =>
    requests.length ? (
      <div className="AddUserToHousePageHouseRequests">
        {requests.map((user) => (
          <UserRequest key={user.sub} {...user} />
        ))}
      </div>
    ) : (
      <></>
    )

  return (
    <div className="AddUserToHousePage">
      <UserHeader />
      <div className="AddUserToHousePageContent">
        <UsersToAdd />
        <HouseRequests />
      </div>
    </div>
  )
}

export default AddUserToHouse

AddUserToHouse.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({ sub: PropTypes.string })),
  requests: PropTypes.arrayOf(
    PropTypes.shape({
      sub: PropTypes.string,
      type: PropTypes.string,
      username: PropTypes.string,
    }),
  ),
  sub: PropTypes.string,
  token: PropTypes.string,
  house: PropTypes.shape({
    houseName: PropTypes.string,
    houseId: PropTypes.string,
  }),
  getAllUsers: PropTypes.func,
  getHouse: PropTypes.func,
  createHouseRequest: PropTypes.func,
  getHouseRequests: PropTypes.func,
}
